import React, { useState, useEffect } from 'react';
import { inject } from "mobx-react";

const Chat = () => {
  return (
    <div className="use-bootstrap">
    </div>
  );
};

export default inject(({ store }) => ({
  user: store.currentUser,
}))(Chat);