/* eslint-disable */
const config = {
  apiHost: "https://slidespielportalsapitest.azurewebsites.net",
  spmApiHost: "https://spm-api.slidespiel.com",
  portalPrice: 40,
  portalPriceForMembers: 30,
  stripeKey: "pk_test_0hlApLH5MBAWQzgK4nfccP1H",
  url: "",
  env: "development",
  portalName: "pm",
  title: "PhilanthropyMiami Ignite",
  hasCme: false,
  questionsBeforeRecording: false,
  portals: [
    { year: 2020, id: "5fd3cc29-4e67-4453-8537-4451a7431293" },
  ],
  defaultYear: 2020,
  amplitudeKey: "5a691f5d28c551997691879d728c1da7",
  // version: "0.9.0",
  version: null,
};

if (process.env.NODE_ENV === "production") {
  Object.assign(config, {
    "apiHost": "https://api.slidespiel.com",
    "stripeKey": "pk_live_nJvbydZp1mBjTIkW4mzW33B5",
    "env": "production",
    "sentryDsn": "https://66ebec47e0014dccb50af5e51f5348eb@o361175.ingest.sentry.io/5264442",
  });
}


export default config;
